import './Main.css';
import brang from '../../images/BrangkasBigl.png';
import crypto from '../../images/crypto.png';
import globe from '../../images/globe.png';
import moneyBag from '../../images/case.png';
import bigEnv from '../../images/bigEnv.png';

function Main() {
  return (
    <div id='main' className="main">
      <div className='main-block'>
        <img className='main-image' src={brang} alt='brang' />
        <div className='main-text'>
          <h1 className='main-header'>НАШ Р2Р КРИПТООБМЕННИК В ЮЖНОЙ КОРЕЕ</h1>
          <p className='text'>Обеспечивает безопасный и быстрый обмен криптовалюты между пользователями</p>
          <form className='button' action='https://t.me/p2p_crypto_ru' target='blank'>
            <button className='button'>Написать</button>
          </form>
        </div>
      </div>
      <div id='mission' className='mission'>
        <h3 className='mission-header'>НАША МИССИЯ</h3>
        <h2 className='mission-purpose'>СДЕЛАТЬ ОБМЕН КРИПТЫ ПРОЩЕ</h2>
        <div className='button-block'>
          <form className='anchor' action='#regular'>
            <button className='button'>Для криптанов</button>
          </form>
          <form className='anchor' action='#abroad'>
            <button className='button'>Для получения денег из других стран</button>
          </form>
          <form className='anchor' action='#business'>
            <button className='button'>Для бизнеса</button>
          </form>
        </div>
        <div className='description'>
          <div id='regular' className='description-container'>
            <img className='description-image' src={crypto} alt='crypto' />
            <h4 className='description-header'>ДЛЯ КРИПТАНОВ</h4>
            <ol className='description-list'>
              <li className='description-item'>Быстрый и безопасный обмен криптовалюты с защитой      от мошенников.</li>
              <li className='description-item'>Для безопасности каждого клиента обязательна процедура KYC, исключая поступление средств                с неизвестного происхождения.</li>
            </ol>
          </div>
          <div id='abroad' className='description-container'>
            <img className='description-image' src={globe} alt='globe' />
            <h4 className='description-header'>ДЛЯ ПОЛУЧЕНИЯ ДЕНЕГ ИЗ ДРУГИХ СТРАН</h4>
            <ol className='description-list'>
              <li className='description-item'>Перевод денег с карты       на карту с использованием криптовалюты. </li>
              <li className='description-item'>Получение средств происходит в течение 10 минут</li>
            </ol>
          </div>
          <div id='business' className='description-container'>
            <img className='description-image' src={moneyBag} alt='case' />
            <h4 className='description-header'>ДЛЯ БИЗНЕСА</h4>
            <ol className='description-list'>
              <li className='description-item'>Помогаем оплатить товары из любой точки мира         с использованием криптовалюты. </li>
              <li className='description-item'>У нас -  простые решения для бизнеса.</li>
            </ol>
          </div>
        </div>
        <div className='client'>
          <img className='client-envelope' src={bigEnv} alt='envelope' />
          <div className='client-description'>
            <h2 className='client-purpose'>ПОЛЬЗОВАТЕЛЬ НА ПЕРВОМ МЕСТЕ</h2>
            <p className='client-text'>Пользователь для нас всегда находится на первом месте. С самого начала защита пользователя является нашим главным приоритетом. В Корее мошенничество широко распространено, и мы создали P2P платформу, чтобы наши клиенты могли совершать обмен своей криптовалюты безопасно,        не рискуя попасть в лапы мошенников.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
