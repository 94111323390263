import './Footer.css';

import inst from '../../images/instagram.png';
import tg from '../../images/telegramIcon.png';

function Footer() {
  let currentYear = new Date().getFullYear();

  return (
    <div className='footer'>
      <div className='footer-module'>
        <p className='footer-header'>Следите за нами</p>
        <div className='footer-social'>
          <form action='https://www.instagram.com/p2p_crypto?igsh=MWp2MDNmNDNpMHc4OQ==' target='blank'>
            <button className='footer-button'>
              <img className='footer-image' src={inst} />
            </button>
          </form>
          <form action='https://t.me/p2p_crypto_ru' target='blank'>
            <button className='footer-button'>
              <img className='footer-image' src={tg} />
            </button>
          </form>
        </div>
      </div>
      <div className='footer-copyright'>
        <p>&#169; {currentYear}  P2PCRYPTO. Все права защищены</p>

      </div>
    </div>
  );
}

export default Footer;
