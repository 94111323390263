import React from 'react';
import './Loader.css';

//Заменить цвета лоадера на желтый черный и белый
function Loader() {
  return (
    <div class="loader-wrapper">
      <div class="loader">
        <div class="loader loader-inner"></div>
      </div>
    </div>
  )
}

export default Loader;