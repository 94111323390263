import React from 'react';
import './Header.css';
import Navigation from '../Navigation/Navigation.js';

import logo from '../../images/logoLarge.png';
import call from '../../images/call.png';


function Header() {
  // добавить бургер меню.
  // <button> <a href=”tel:+380441111111”>+Позвоните нам</a> </button>

  return (
    <div className='header'>
      <div className='header_menu'><Navigation /></div>
      <div className='logo_image'>
        <img className='logo_element' src={logo} alt='logo' />
      </div>

      <div className='descktop_menu'>
        <form className='descktop_anchor' action='#main'>
          <button className='descktop_button'>ГЛАВНАЯ</button>
        </form>
        <form className='descktop_anchor' action='#mission'>
          <button className='descktop_button'>НАША МИССИЯ</button>
        </form>
        <form className='descktop_anchor' action='#office'>
          <button className='descktop_button'>ОФИС</button>
        </form>
        <form className='descktop_anchor' action='#contacts'>
          <button className='descktop_button'>КОНТАКТЫ</button>
        </form>
      </div>

      <div className='image_container'>
        <form action='tel:+821043428607' target='blank'>
          <button className='header_button'>
            <img className='call_element' src={call} alt='call' />
          </button>
        </form>
        {/*         <button>
          <a href='tel:+821043428607'>
            <img className='call_element' src={call} alt='call' />
          </a>
        </button>
 */}
      </div>
    </div >
  )
}

export default Header;