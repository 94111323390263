import './App.css';
import Header from '../Header/Header.js';
import Loader from '../Loader/Loader.js';
import Main from '../Main/Main.js';
import Contacts from '../Contacts/Contacts.js';
import Partners from '../Partners/Partners.js';
import Footer from '../Footer/Footer.js';


function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Contacts />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
