import React, { useState } from 'react';


import './Navigation.css';

function Navigation() {

  const [checked, setChecked] = useState(false)
  const lock = (() => {
    if (document.getElementById('menu').checked) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = '';
    }
  })

  return (
    <section className='navigation'>
      <input onClick={lock} type='checkbox' id='menu' />
      <label htmlFor='menu' className='checkbox-button'>
        <span className='line line-main' />
        <span className='line line-split' />
      </label>
      <nav className='navigation-menu'>
        <div className='menu__link'>
          <a href='/' className='navigation-menu-list'>Главная</a>
          <a href='/movies' className='navigation-menu-list'>Наша миссия</a>
          <a href='/saved-movies' className='navigation-menu-list'>Офис</a>
          <a href='/saved-movies' className='navigation-menu-list'>Контакты
          </a>
        </div>
      </nav>

    </section >
  );
}

export default Navigation;
