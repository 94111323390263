import './Partners.css';

import tgIcon from '../../images/chinguru.png'



function Partners() {
  return (
    <div className='partner-card_list'>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>
      <div className='partner-card'>
        <img className='test' src={tgIcon} alt='telegramIcon' />
      </div>

    </div>
  );
}

export default Partners;
